<!--
 * @Description: 联系人table
 * @Author: ChenXueLin
 * @Date: 2021-10-08 09:19:15
 * @LastEditTime: 2022-06-14 11:17:16
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="wrap">
    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table
        border
        height="300px"
        :data="contactInfos"
        highlight-current-row
      >
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.$index + 1 }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="120">
          <template slot-scope="scope">
            <e6-td-operate
              :data="getOperateList()"
              @command="handleOperate($event, scope.$index)"
            ></e6-td-operate>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
export default {
  name: "contact",
  data() {
    return {
      value: "",
      columnData: [
        {
          fieldName: "peopleName",
          display: true,
          fieldLabel: "姓名",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "contactTypeName",
          display: true,
          fieldLabel: "联系人职责",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone1",
          display: true,
          fieldLabel: "联系电话1",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone2",
          display: true,
          fieldLabel: "联系电话2",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "hkPhone",
          display: true,
          fieldLabel: "香港电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "detailAddress",
          display: true,
          fieldLabel: "所在地区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //表头
    };
  },
  props: {
    contactInfos: {
      type: Array,
      default: () => []
    },
    corpId: {
      type: [String, Number],
      default: ""
    }
  },
  mixins: [base, listPage],

  computed: {},

  mounted() {},

  methods: {
    // 获取页面操作权限
    getOperateList() {
      if (this.corpId) {
        return [];
      }
      // 页面按钮权限
      let operates = [
        {
          label: "删除",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, index) {
      if (val.id == 1) {
        this.$emit("handleDelete", { type: 1, index: index });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrap {
  margin-top: 10px;
}
</style>
