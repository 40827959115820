<!--
 * @Description: 
 * @Author: LiangYiNing
 * @Date: 2022-06-10 09:29:33
 * @LastEditTime: 2022-07-20 15:12:17
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="slect-real-Time-query-content">
    <e6-vr-select
      v-model="selectValue"
      :data="dataList"
      :loading="loading"
      :placeholder="placeholder"
      :title="title"
      @filterChange="handleFilter"
      @change="selectChange"
      @clear="clearHandle"
      :props="propsConfig"
      :multiple="multiple"
      :popover-width="popoverWidth"
      virtual
      :clearable="clearable"
      :disabled="disabled"
      remote
      :is-title="true"
    >
      <template v-slot:label="slotProps" v-if="showSlot">
        <div class="option-content">
          <span class="options-remark-label ellipsis">{{
            slotProps.node.label
          }}</span>
          <span class="option-remark ellipsis">{{
            slotProps.node[slotKey]
          }}</span>
        </div>
      </template>
    </e6-vr-select>
  </div>
</template>
<script>
import { typeOf, printError, get } from "@/utils/util";
let VALUE_TYPE_ARRAY = "Array";
import { getCorpMsg } from "@/api";

export default {
  name: "SelectRealTimeQuery",
  data() {
    return {
      selectValue: "",
      loading: false,
      selectedList: [], // 已选中的数据 主要用于数据过滤后仍能稳定显示已选择的值
      dataList: [] // 下拉选择数据
    };
  },
  watch: {
    value() {
      if (this.testValueChange()) {
        this.selectValue = this.multiple ? this.value || [] : this.value;
      }
    },
    defaultDataList(val) {
      if (val.length) {
        this.selectedList = val;
        this.pushSelectedDataIntoDataList();
      }
    }
  },
  props: {
    propsConfig: {
      type: Object,
      default: () => {
        return {
          id: "corpId",
          label: "corpName"
        };
      }
    },
    value: {
      type: [String, Number, Array]
    },
    placeholder: {
      type: String,
      default: "客户名称"
    },
    title: {
      type: String,
      default: "客户名称"
    },
    clearable: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    needInitDropDownList: {
      type: Boolean,
      default: true
    },
    queryListAPI: {
      type: Function,
      default: getCorpMsg
    },
    dataPath: {
      type: String,
      default: "data.array"
    },
    multiple: {
      type: Boolean
    },
    pageSize: {
      type: Number,
      default: 200
    },
    searchValueKey: {
      type: String,
      default: "corpName"
    },
    searchData: {
      type: Object,
      default: () => {
        return {
          corpId: "",
          pageNum: 1,
          isManageEmp: false
        };
      }
    },
    defaultDataList: {
      type: Array,
      default: () => []
    },
    showSlot: {
      type: Boolean,
      default: false
    },
    slotKey: {
      type: String,
      default: "id"
    },
    popoverWidth: {
      type: Number,
      default: 0
    }
  },
  created() {
    if (this.testValueChange()) this.selectValue = this.value;
    if (this.multiple) this.selectValue = this.value || [];
    if (this.defaultDataList.length) {
      this.pushSelectedDataIntoDataList();
    }
    if (this.needInitDropDownList) {
      this.loadData();
    }
  },
  methods: {
    //加载加载数据
    async loadData(val) {
      try {
        this.loading = true;
        let res = await this.queryListAPI({
          ...this.searchData,
          pageSize: this.pageSize,
          [this.searchValueKey]: val
        });
        //获取客户名称
        let dataList = this.getFreezeResponse(res, {
          path: this.dataPath
        });
        this.pushSelectedDataIntoDataList(dataList);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 客户列表 远程搜索
    handleFilter: _.debounce(async function(val) {
      if (val) {
        this.loadData(val);
      }
    }, 300),
    // 将选中数据 加入下拉列表 已保证选择的数据稳定显示
    pushSelectedDataIntoDataList(dataList) {
      if (this.selectedList.length) {
        dataList = dataList || this.dataList;
        let { id } = this.propsConfig;
        let fliterArr = this.selectedList.filter(each => {
          return dataList.every(item => {
            return item[id] != each[id];
          });
        });
        if (fliterArr.length) {
          dataList = [...fliterArr, ...dataList];
        }
      }
      this.dataList = dataList;
    },
    /*
     ** 处理后台返回的数据，默认 code=1 时，根据路径获取 object 中的属性值
     */
    getFreezeResponse(
      object,
      { path = "result", defaultVal = [], freeze = true, code = ["OK"] } = {}
    ) {
      if (code.includes(object.code)) {
        return get(object, path, defaultVal, freeze);
      } else {
        return defaultVal;
      }
    },
    // value 测试值是否在外部发生改变
    testValueChange() {
      if (this.multiple && typeOf(this.value) !== VALUE_TYPE_ARRAY) {
        throw new Error(
          "components 'selectRealTimeQuery' need value type is 'Array' that Attr is 'multiple'"
        );
      } else if (this.multiple) {
        return JSON.stringify(this.value) !== JSON.stringify(this.selectValue);
      }
      return this.value !== this.selectValue;
    },
    // 选择触发事件
    selectChange(val, dataSelected) {
      if (this.multiple) {
        let { id } = this.propsConfig;
        if (typeOf(dataSelected) === VALUE_TYPE_ARRAY) {
          // 全选/取消全选时 dataSelected 为所选数组
          this.selectedList = val ? [...this.dataList] : [];
        } else if (val) {
          this.selectedList.push(dataSelected);
        } else {
          this.selectedList = this.selectedList.filter(item => {
            return item[id] !== dataSelected[id];
          });
        }
      } else {
        this.selectedList = [dataSelected];
      }
      this.$nextTick(() => {
        let value = this.selectValue;
        this.$emit("input", value);
        this.$emit("change", value, dataSelected);
      });
    },
    // 清空事件
    clearHandle() {
      this.selectedList = [];
      let value = this.multiple ? [] : this.selectValue;
      this.$emit("input", value);
      this.$emit("clear", value, null);
    }
  }
};
</script>
<style lang="scss" scoped>
.slect-real-Time-query-content {
  width: 100%;
  .e6-vr-select {
    width: 100%;
  }
}
$--color-option-tips-1: #f89191; // 异常结束
$--color-option-remark-1: #909399; // 备注
.slect-real-Time-query-content {
  width: 100%;
  .e6-vr-select {
    width: 100%;
  }
}
/deep/.option-content {
  position: relative;
  .option-label {
    display: inline-block;
    width: calc(100% - 20px);
  }
  .option-tips {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-top: 3px;
    font-size: 10px;
    color: $--color-option-tips-1;
    border: solid 1px $--color-option-tips-1;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
  }
  .option-remark {
    display: inline-block;
    height: 16px;
    width: 57px;
    line-height: 16px;
    margin-top: 3px;
    margin-right: 3px;
    font-size: 10px;
    color: $--color-option-remark-1;
    border-radius: 2px;
    text-align: right;
    vertical-align: top;
  }
  .options-remark-label {
    display: inline-block;
    width: calc(100% - 60px);
  }
}
</style>
