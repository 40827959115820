<!--
 * @Description: 添加联系人弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-15 16:38:03
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 新增联系人弹框 -->
  <el-dialog
    v-dialogDrag
    title="新增联系人"
    :visible.sync="addContactVisible"
    width="800px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
  >
    <el-form
      ref="addContactForm"
      label-width="100px"
      :model="addContactForm"
      :rules="rules"
      :inline="true"
    >
      <el-form-item label="所属公司" prop="corpId" v-if="displayCrop">
        <el-input
          disabled
          v-model="addContactForm.corpName"
          placeholder="所属公司"
          title="所属公司"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人姓名" prop="contactName">
        <el-input
          v-model.trim="addContactForm.contactName"
          placeholder="联系人姓名"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人职位" prop="contactDutyText">
        <el-input
          v-model.trim="addContactForm.contactDutyText"
          placeholder="联系人职位"
          maxlength="512"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人职责" prop="contactType">
        <e6-vr-select
          v-model="addContactForm.contactType"
          :data="contactTypeList"
          placeholder="联系人职责"
          title="联系人职责"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          multiple
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="微信号" prop="wechatid">
        <el-input
          v-model.trim="addContactForm.wechatid"
          placeholder="微信号"
          maxlength="256"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <e6-vr-select
          v-model="addContactForm.sex"
          :data="[
            { id: 1, label: '男' },
            { id: 2, label: '女' }
          ]"
          placeholder="性别"
          title="性别"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <div>
        <el-form-item
          label="所在地区"
          prop="defaultCity"
          :rules="addressSelectRules"
        >
          <city
            @selected="handleCitySelect"
            :defaultCity.sync="addContactForm.defaultCity"
            ref="citySelect"
            width="220px"
            clearable
          ></city>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="addContactForm.address"
            placeholder="详细地址"
            maxlength="255"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item label="籍贯" prop="nativePlace">
        <el-input
          v-model.trim="addContactForm.nativePlace"
          placeholder="籍贯"
        ></el-input>
      </el-form-item>
      <el-form-item label="婚否" prop="marriage">
        <e6-vr-select
          v-model="addContactForm.marriage"
          :data="[
            { id: 1, label: '未婚' },
            { id: 2, label: '已婚' }
          ]"
          placeholder="婚否"
          title="婚否"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="联系电话1" prop="phone1">
        <el-input
          v-model.trim="addContactForm.phone1"
          placeholder="联系电话1"
          maxlength="200"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话2" prop="phone2">
        <el-input
          v-model.trim="addContactForm.phone2"
          placeholder="联系电话2"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item label="香港电话" prop="hkPhone">
        <el-input
          v-model.trim="addContactForm.hkPhone"
          placeholder="香港电话"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item label="英文名" prop="englishName">
        <el-input
          v-model="addContactForm.englishName"
          placeholder="英文名"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item label="出生日期" prop="birthday">
        <el-date-picker
          v-model="addContactForm.birthday"
          :picker-options="pickerOptions1"
          type="datetime"
          placeholder="选择日期"
          value-format="timestamp"
          popper-class="special"
          default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="邮箱" prop="emailAddr">
        <el-input
          v-model.trim="addContactForm.emailAddr"
          placeholder="邮箱"
          maxlength="512"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idCard">
        <el-input
          v-model.trim="addContactForm.idCard"
          placeholder="身份证号"
          maxlength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="pushData">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { createContact, findDownList } from "@/api";
import { printError } from "@/utils/util";
import {
  telAndMobileValid,
  IDNOValid,
  addressSelectvalid,
  zhCNOrEnglishValid,
  zhCNValid,
  EnglishValid
} from "@/utils/validate";
export default {
  name: "",
  components: {},
  data() {
    return {
      /***新建*****/
      addContactForm: {
        corpId: "", //客户id
        corpName: "", //客户名称
        contactName: "", //联系人姓名
        contactDutyText: "", //联系人职位
        contactType: [], //联系人职责
        birthday: "", //出生日期
        idCard: "", //身份证号码
        sex: "", //性别
        marriage: "", //婚否
        emailAddr: "", //邮箱
        wechatid: "", //微信号
        englishName: "", //英文名
        nativePlace: "", //籍贯
        phone1: "", //电话1
        phone2: "", //电话2
        hkPhone: "", //香港电话
        address: "", //详细地址
        defaultCity: "", // 省市区校验字段
        addressList: [] //常用地址
      }, //新建联系人Form
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp > nowTime;
        }
      },
      corpList: [], //公司名称下拉框
      addressInfo: {}, //地址相关信息
      contactTypeList: [] //客户职责下拉框
    };
  },
  props: {
    addContactVisible: {
      type: Boolean,
      default: false
    },
    corpId: {
      type: [String, Number],
      default: ""
    },
    corpName: {
      type: String,
      default: ""
    },
    isNeedReq: {
      type: Boolean,
      default: false
    },
    displayCrop: {
      type: Boolean,
      default: true
    },
    initPhone: {
      type: [String, Number]
    }
  },
  mixins: [base],
  computed: {
    // 省市区地址校验规则
    addressSelectRules() {
      return {
        validator: addressSelectvalid({ level: 3, target: this.addressInfo }),
        trigger: ["blur", "change"]
      };
    },
    rules() {
      return {
        contactName: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: ["blur", "change"]
          },
          {
            validator: zhCNOrEnglishValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        contactDutyText: [
          {
            validator: zhCNOrEnglishValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        nativePlace: [
          {
            validator: zhCNValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        phone1: [
          {
            required: true,
            message: "请输入联系电话1",
            trigger: ["blur", "change"]
          },
          {
            validator: telAndMobileValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        phone2: [
          {
            validator: telAndMobileValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        englishName: [
          {
            validator: EnglishValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        hkPhone: [
          {
            validator: telAndMobileValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        idCard: [
          {
            validator: IDNOValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        emailAddr: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ]
      };
    }
  },
  watch: {
    addContactVisible(val) {
      if (val) {
        this.initData();
        this.addContactForm.corpId = this.corpId * 1;
        this.addContactForm.corpName = this.corpName;
        this.addContactForm.phone1 = this.initPhone || "";
      }
    }
  },
  created() {},
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["contactTypeCode"])];
        let [contactTypeRes] = await Promise.all(promiseList);
        //客户职责下拉框
        this.contactTypeList = this.getFreezeResponse(contactTypeRes, {
          path: "data.contactTypeCode"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 处理城市选择
    handleCitySelect(val) {
      let addressInfo = this.addressInfo;
      addressInfo.provinceId = val.province.code;
      addressInfo.provinceName = val.province.name;
      addressInfo.cityId = val.city.code;
      addressInfo.cityName = val.city.name;
      addressInfo.areaId = val.district.code;
      addressInfo.areaName = val.district.name;
      addressInfo.streetId = val.street.code;
      addressInfo.streetName = val.street.name;
      this.addContactForm.defaultCity = `${val.province.name}${val.city.name}${val.district.name}${val.street.name}`;
    },
    //点击确定
    pushData() {
      this.$refs.addContactForm.validate(valid => {
        if (valid) {
          let addressInfo = this.addressInfo;
          if (this.addContactForm.address) {
            addressInfo.address = this.addContactForm.address;
          }
          // 所在区域回显处理
          this.addContactForm.detailAddress =
            (addressInfo.provinceName
              ? addressInfo.provinceName +
                addressInfo.cityName +
                addressInfo.areaName
              : "") +
            (this.addContactForm.address ? this.addContactForm.address : "");
          if (this.addressInfo.provinceName) {
            this.addressInfo.address = this.addContactForm.address;
            this.addContactForm.addressList.push(this.addressInfo); //处理地址
          }

          let contactTypeNameList = this.contactTypeList.filter(v => {
            return this.addContactForm.contactType.indexOf(v.id) !== -1; // 利用filter方法来遍历是否有相同的元素
          });
          let contactTypeName = contactTypeNameList
            .map(obj => {
              return obj.label;
            })
            .join(",");
          //处理职责中文显示
          this.addContactForm.contactTypeName = contactTypeName;
          if (this.isNeedReq) {
            this.addConcat();
          } else {
            this.$emit("handlePush", {
              type: 1,
              data: _.cloneDeep(this.addContactForm)
            });
            this.closeDialog();
            this.$emit("handleClose", "addContactVisible");
          }
        }
      });
    },
    //新增联系人请求
    async addConcat() {
      try {
        let res = await createContact({
          ...this.addContactForm,
          corpId: this.corpId
        });
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.closeDialog();
          this.$emit("handleClose", "addContactVisible");
          this.$emit("updateList");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击取消
    closeDialog() {
      this.addContactForm = {
        corpId: "", //客户id
        corpName: "", //客户id
        contactName: "", //联系人姓名
        contactDutyText: "", //联系人职位
        contactType: [], //联系人职责
        birthday: "", //出生日期
        idCard: "", //身份证号码
        sex: "", //性别
        marriage: "", //婚否
        emailAddr: "", //邮箱
        wechatid: "", //微信号
        englishName: "", //英文名
        nativePlace: "", //籍贯
        phone1: "", //电话1
        phone2: "", //电话2
        hkPhone: "", //香港电话
        address: "", //详细地址
        defaultCity: "", // 省市区校验字段
        addressList: [] //常用地址
      };
      this.$refs.addContactForm.resetFields();
      this.$refs.citySelect.clearCity();
      this.$emit("handleClose", "addContactVisible");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding: 20px;
    overflow-y: auto;
    .el-input {
      width: 220px;
    }
  }
}
</style>
