<!--
 * @Description: 新增客户
 * @Author: ChenXueLin
 * @Date: 2021-10-20 18:09:46
 * @LastEditTime: 2022-08-12 13:49:03
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 开票信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">开票信息</div>
            <el-form
              class="editForm"
              ref="invoiceDataForm"
              label-width="160px"
              :inline="true"
              :model="corpInvoiceInfo"
              :rules="invoiceDataRules"
              :disabled="!!corpId"
            >
              <el-form-item label="开票名称" prop="billingCorpId">
                <select-real-Time-query
                  v-model="corpInvoiceInfo.billingCorpId"
                  :defaultDataList="billingCorpNameEnum"
                  :queryListAPI="getInvoiceDownList"
                  :disabled="!!corpId"
                  :showSlot="true"
                  slotKey="taxNo"
                  :popoverWidth="300"
                  :searchData="{
                    pageIndex: 1,
                    pageSize: 200
                  }"
                  @change="billingCorpIdChange"
                  @clear="billingCorpIdChange"
                  :propsConfig="{
                    id: 'billingCorpId',
                    label: 'billingCorpName'
                  }"
                  searchValueKey="billingCorpName"
                  title="开票名称"
                  placeholder="开票名称"
                  clearable
                ></select-real-Time-query>
              </el-form-item>

              <el-form-item label="客户名称" prop="customerName">
                <el-input
                  disabled
                  placeholder="客户名称"
                  v-model.trim="corpInvoiceInfo.customerName"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业类型" prop="bssType">
                <e6-vr-select
                  v-model="corpInvoiceInfo.bssType"
                  :data="bssTypeEnum"
                  placeholder="企业类型"
                  title="企业类型"
                  :props="propsEnum"
                  clearable
                  disabled
                ></e6-vr-select>
              </el-form-item>
            </el-form>
          </div>
          <!-- 开票信息 end -->
          <!-- 客户基础信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">客户基础信息-CRM</div>
            <el-form
              class="editForm"
              ref="corpBaseFormCRM"
              label-width="160px"
              :inline="true"
              :model="corpBaseInfo"
              :rules="corpBaseRules"
              :disabled="!!corpId"
            >
              <el-form-item label="系统客户名称" prop="corpName">
                <el-input
                  placeholder="系统客户名称"
                  v-model.trim="corpBaseInfo.corpName"
                  maxlength="64"
                ></el-input>
              </el-form-item>
              <el-form-item label="产品线" prop="productLine">
                <e6-vr-select
                  v-model="corpBaseInfo.productLine"
                  :data="corpProductLineTypeEnum"
                  placeholder="产品线"
                  title="产品线"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="业务员" prop="salesman">
                <all-user-search
                  v-model="corpBaseInfo.salesman"
                  clear
                  placeholder="业务员"
                  title="业务员"
                  :propsConfig="{
                    id: 'employeeId',
                    label: 'userName'
                  }"
                  :showSlot="true"
                  :disabled="!!corpId"
                  :initValue="rollOutSalesmanInfo"
                >
                </all-user-search>
              </el-form-item>
              <el-form-item label="所属主体" prop="companyId">
                <e6-vr-select
                  v-model="corpBaseInfo.companyId"
                  :data="companyEnum"
                  placeholder="所属主体"
                  title="所属主体"
                  :props="{
                    id: 'companyId',
                    label: 'companyFullName'
                  }"
                  clearable
                  v-if="!corpId"
                ></e6-vr-select>
                <el-input
                  v-else
                  disabled
                  v-model="corpBaseInfo.companyName"
                ></el-input>
              </el-form-item>
              <el-form-item label="客户类别" prop="corpCategory">
                <e6-vr-select
                  v-model="corpBaseInfo.corpCategory"
                  :data="corpCategoryEnum"
                  placeholder="客户类别"
                  title="客户类别"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="业务类型" prop="corpBizType">
                <e6-vr-select
                  v-model="corpBaseInfo.corpBizType"
                  :data="corpBizTypeEnum"
                  placeholder="业务类型"
                  title="业务类型"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="客户分级" prop="corpClass">
                <e6-vr-select
                  v-model="corpBaseInfo.corpClass"
                  :data="corpClassEnum"
                  placeholder="客户分级"
                  title="客户分级"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="客户地址">
                <div :class="{ 'address-box': !corpId }">
                  <city
                    v-if="!corpId"
                    @selected="handleCitySelect"
                    ref="citySelect"
                    :defaultCity.sync="corpBaseInfo.defualtCity"
                  ></city>
                  <el-input
                    v-else
                    :placeholder="getPlaceholder('省市区街道')"
                    v-model="corpBaseInfo.defualtCity"
                  ></el-input>
                  <el-input
                    :placeholder="getPlaceholder('详细地址')"
                    v-model="corpBaseInfo.address.addressDetail"
                    maxlength="128"
                  ></el-input>
                </div>
              </el-form-item>
            </el-form>
          </div>

          <div class="baseInfo-box">
            <div class="edit-title">客户基础信息</div>
            <el-form
              label-width="160px"
              :inline="true"
              :model="corpBaseInfo"
              class="editForm"
              ref="corpBaseFormE3"
              :rules="corpBaseRules"
              :disabled="!!corpId"
            >
              <el-form-item label="开票合同名称" prop="systemCorpName">
                <el-input
                  placeholder="开票合同名称"
                  v-model.trim="corpBaseInfo.systemCorpName"
                  maxlength="512"
                ></el-input>
              </el-form-item>
              <el-form-item label="客户简称" prop="corpSimName">
                <el-input
                  :placeholder="getPlaceholder('客户简称')"
                  v-model.trim="corpBaseInfo.corpSimName"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否总公司" prop="isMaster">
                <e6-vr-select
                  v-model="corpBaseInfo.isMaster"
                  :data="[
                    { id: 1, label: '是' },
                    { id: 2, label: '否' }
                  ]"
                  placeholder="是否总公司"
                  title="是否总公司"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="上级公司" prop="parentCorpId">
                <select-real-Time-query
                  v-model="corpBaseInfo.parentCorpId"
                  :defaultDataList="parentCorpEnum"
                  :needInitDropDownList="false"
                  :disabled="!!corpId"
                  title="上级公司"
                  placeholder="上级公司"
                  clearable
                ></select-real-Time-query>
              </el-form-item>
              <el-form-item label="客户属性" prop="corpAttr">
                <e6-vr-select
                  v-model="corpBaseInfo.corpAttr"
                  :data="corpAttrEnum"
                  :placeholder="getPlaceholder('客户属性')"
                  title="客户属性"
                  :props="{
                    id: 'id',
                    label: 'attrValueDesc'
                  }"
                  multiple
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="客户来源" prop="corpSource">
                <e6-vr-select
                  v-model="corpBaseInfo.corpSource"
                  :data="corpSourceEnum"
                  placeholder="客户来源"
                  title="客户来源"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="客户类型" prop="corpDiff">
                <e6-vr-select
                  v-model="corpBaseInfo.corpDiff"
                  :data="corpDiffEnum"
                  placeholder="客户类型"
                  title="客户类型"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="自动确认" prop="autoConfirm">
                <e6-vr-select
                  v-model="corpBaseInfo.autoConfirm"
                  :data="[
                    { id: 1, label: '是' },
                    { id: 0, label: '否' }
                  ]"
                  placeholder="自动确认"
                  title="自动确认"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="客户状态" prop="corpStatus">
                <e6-vr-select
                  v-model="corpBaseInfo.corpStatus"
                  :data="corpStatusEnum"
                  placeholder="客户状态"
                  title="客户状态"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="客户级别" prop="corpLevel">
                <e6-vr-select
                  v-model="corpBaseInfo.corpLevel"
                  :data="corpLevelEnum"
                  placeholder="客户级别"
                  title="客户级别"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="协同人" prop="cooIds">
                <all-user-search
                  v-model="corpBaseInfo.cooIds"
                  clearable
                  placeholder="协同人"
                  title="协同人"
                  :propsConfig="{
                    id: 'employeeId',
                    label: 'userName'
                  }"
                  multiple
                  :showSlot="true"
                  :disabled="!!corpId"
                  :initValue="cooIdsInfo"
                >
                </all-user-search>
              </el-form-item>
              <el-form-item label="客户经理" prop="customerManagerId">
                <all-user-search
                  v-model="corpBaseInfo.customerManagerId"
                  clear
                  placeholder="客户经理"
                  title="客户经理"
                  :propsConfig="{
                    id: 'employeeId',
                    label: 'userName'
                  }"
                  :showSlot="true"
                  :disabled="!!corpId"
                  :initValue="customermanagerInfo"
                >
                </all-user-search>
              </el-form-item>
              <el-form-item label="费用类型" prop="corpType">
                <e6-vr-select
                  v-model="corpBaseInfo.corpType"
                  :data="corpFeeTypeEnum"
                  :placeholder="getPlaceholder('费用类型')"
                  title="费用类型"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="运营路线" prop="mainRoutes">
                <el-input
                  :placeholder="getPlaceholder('运营路线')"
                  v-model="corpBaseInfo.mainRoutes"
                  maxlength="512"
                ></el-input>
              </el-form-item>
              <el-form-item label="安装初衷" prop="corpInstallReason">
                <e6-vr-select
                  :data="corpInstallreasonEnum"
                  :placeholder="getPlaceholder('安装初衷')"
                  title="安装初衷"
                  :disabled="!!corpId"
                  v-model="corpBaseInfo.corpInstallReason"
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="主要车型" prop="mainModel">
                <el-input
                  :placeholder="getPlaceholder('主要车型')"
                  v-model="corpBaseInfo.mainModel"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-form-item label="客户网址" prop="corpUrl">
                <el-input
                  :placeholder="getPlaceholder('客户网址')"
                  v-model="corpBaseInfo.corpUrl"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-form-item label="客户经办人" prop="clientManager">
                <all-user-search
                  v-model="corpBaseInfo.clientManager"
                  clear
                  placeholder="客户经办人"
                  title="客户经办人"
                  :propsConfig="{
                    id: 'employeeId',
                    label: 'userName'
                  }"
                  :showSlot="true"
                  :disabled="!!corpId"
                  :initVal="clientManagerList"
                >
                </all-user-search>
              </el-form-item>
              <el-form-item label="转介绍客户" prop="corpReferral">
                <select-real-Time-query
                  v-model="corpBaseInfo.corpReferral"
                  :defaultDataList="corpReferralEnum"
                  :needInitDropDownList="false"
                  :disabled="!!corpId"
                  title="转介绍客户"
                  placeholder="转介绍客户"
                  clearable
                ></select-real-Time-query>
              </el-form-item>
              <el-form-item label="客户影响力" prop="corpImpact">
                <e6-vr-select
                  v-model="corpBaseInfo.corpImpact"
                  :data="corpImpactEnum"
                  :placeholder="getPlaceholder('客户影响力')"
                  title="客户影响力"
                  :props="propsEnum"
                  clearable
                  :disabled="!!corpId"
                ></e6-vr-select>
              </el-form-item>
            </el-form>
          </div>

          <!-- 客户备注信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">客户备注信息</div>
            <el-form
              label-width="160px"
              :inline="true"
              :model="corpBaseInfo"
              class="editForm"
              :disabled="!!corpId"
            >
              <el-form-item label="业务备注" prop="remarkOp">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="业务备注"
                  v-model="corpBaseInfo.remarkOp"
                  style="width:350px"
                  maxlength="100"
                ></el-input>
              </el-form-item>
              <el-form-item label="服务备注" prop="remarkCs">
                <el-input
                  style="width:350px"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="服务备注"
                  v-model="corpBaseInfo.remarkCs"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>

          <!-- 客户联系人  start -->
          <div class="tabs-wrapper">
            <el-tabs v-model="activeName">
              <el-tab-pane label="客户联系人" name="one">
                <contact
                  :corpId="corpId"
                  :addContactVisible="addContactVisible"
                  :contactInfos="contactInfos"
                  @handleDelete="handleDelete"
                ></contact>
              </el-tab-pane>
            </el-tabs>
            <section class="operate-button">
              <div class="operate-action">
                <i
                  v-if="!corpId"
                  class="e6-icon-add_line"
                  title="新增"
                  @click="handleAdd"
                ></i>
              </div>
            </section>
          </div>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack()">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </section>
    </div>
    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      :displayCrop="false"
      @handleClose="handleClose"
      @handlePush="handlePush"
    ></add-concat-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import goBack from "@/mixins/goBack";
import rulesAndEnumsOfAddMixin from "@/views/OMS/customerManage/customerList/mixins/rulesAndEnumsOfAddMixin";
import Contact from "./contact.vue";
import { printError } from "@/utils/util";
import { createCorp, getCorpDetailBefore, getInvoiceDownList } from "@/api";
// import CustomerRelations from "./customerRelations.vue";
import AddConcatDialog from "@/components/addConcatDialog.vue";
// import AddRelationsDialog from "./components/addRelationsDialog.vue";
import allUserSearch from "@/components/allUserSearch";
import selectRealTimeQuery from "@/components/selectRealTimeQuery";
export default {
  name: "addCustomer",
  components: {
    Contact,
    allUserSearch,
    AddConcatDialog,
    selectRealTimeQuery
  },
  mixins: [base, goBack, rulesAndEnumsOfAddMixin],
  data() {
    return {
      corpId: "",
      getInvoiceDownList,
      corpInvoiceInfo: {
        billingCorpId: "", //开票ID
        billingCorpName: "", //开票名称
        customerName: "", // 开票客户名称
        bssType: "" //企业类型
      }, //开票信息
      corpBaseInfo: {
        // 客户基础信息-CRM
        systemCorpName: "", //开票合同名称
        corpName: "", //系统客户名称
        productLine: "", //产品线
        salesman: "", //业务员

        companyId: "", //所属主体
        corpCategory: "", //客户类别
        corpBizType: "", //业务类型
        corpClass: "", //客户分级

        isMaster: "", //是否总公司
        parentCorpId: "", //上级公司
        defualtCity: "", // 省市区街道
        address: {
          provinceCode: "", // 省
          provinceName: "", // 省
          cityCode: "", // 市
          cityName: "", // 市
          countyCode: "", // 区
          countyName: "", // 区
          streetCode: "", // 街道
          streetName: "", // 街道
          addressDetail: "" // 详细地址
        },
        // 客户基础信息-E3
        corpSimName: "", //客户简称
        corpAttr: [], //客户属性
        corpSource: "", //客户来源
        corpDiff: "", //客户类型

        autoConfirm: "", //自动确认
        corpStatus: "", //客户状态
        corpLevel: "", //客户级别
        cooIds: [], //协同人

        customerManagerId: "", //客户经理
        corpType: "", //费用类型
        mainRoutes: "", //运营线路 （路线）
        corpInstallReason: "", //安装初衷

        mainModel: "", //主要车型
        corpUrl: "", //客户网址
        clientManager: "", //客户经办人
        corpReferral: "", //转介绍公司

        corpImpact: "", //客户影响力
        // 备注信息
        remarkCs: "", //服务部备注
        remarkOp: "" //业务备注
      }, //基本信息
      contactInfos: [], //客户联系人列表
      cooIdsInfo: [],
      customermanagerInfo: [],
      clientManager: [],
      icbcChangeAddrFileList: [],
      clientManagerList: [],
      billingCorpId: "",
      searchFormVisiable: false,
      activeName: "one",
      // 点击新建联系人
      addContactVisible: false
    };
  },
  watch: {},
  computed: {
    rollOutSalesmanInfo() {
      let userInfo = this.$store.state.userInfo;
      let rollOutSalesmanInfo = userInfo.employeeId
        ? [
            {
              employeeId: userInfo.employeeId,
              userId: userInfo.userId,
              employeeName: userInfo.userName,
              userName: userInfo.userName
            }
          ]
        : [];
      return rollOutSalesmanInfo;
    }
  },
  created() {
    let hasParamsRoute = this.getHasParamsRoute(); // base Mixin的方法
    this.corpId = hasParamsRoute.params.approvalBusinessId;
    // 默认业务员为当前账号
    this.corpBaseInfo.salesman = this.$store.state.userInfo.employeeId;
    if (this.corpId) {
      this.getCorpDetailBefore();
    }
  },
  mounted() {},
  methods: {
    //获取客户详情
    async getCorpDetailBefore() {
      try {
        this.loading = true;
        let res = await getCorpDetailBefore({ corpId: this.corpId });
        //客户信息
        let corpBaseInfo = this.getFreezeResponse(res, {
          path: "data.corpBaseInfo"
        });
        //开票信息
        let corpInvoiceInfo = this.getFreezeResponse(res, {
          path: "data.corpInvoiceInfo"
        });
        let contactInfos = this.getFreezeResponse(res, {
          path: "data.contactInfos"
        });
        this.initeBaseInfoData(corpBaseInfo, corpInvoiceInfo, contactInfos);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 初始化数据
    initeBaseInfoData(corpBaseInfo, corpInvoiceInfo, contactInfos) {
      // 基础数据
      corpBaseInfo = JSON.parse(JSON.stringify(corpBaseInfo));
      for (let key in corpBaseInfo) {
        corpBaseInfo[key] = corpBaseInfo[key] === null ? "" : corpBaseInfo[key];
      }
      this.corpBaseInfo = corpBaseInfo;
      let needToStringKey = [
        "corpSource",
        "corpDiff",
        "corpCategory",
        "corpBizType",
        "corpStatus",
        "corpLevel",
        "corpClass",
        "corpImpact",
        "corpInstallReason",
        "corpType"
      ];
      needToStringKey.map(item => {
        this.corpBaseInfo[item] += "";
      });
      this.$set(
        this.corpBaseInfo,
        "defualtCity",
        this.getDefualtCity(this.corpBaseInfo.address)
      );

      if (this.corpBaseInfo.parentCorpId) {
        this.parentCorpEnum = [
          {
            corpId: this.corpBaseInfo.parentCorpId,
            corpName: this.corpBaseInfo.parentCorpName
          }
        ];
      }
      if (this.corpBaseInfo.corpReferral) {
        this.corpReferralEnum = [
          {
            corpId: this.corpBaseInfo.corpReferral,
            corpName: this.corpBaseInfo.corpReferralName
          }
        ];
      }
      // 协同人回显
      let coordList = this.corpBaseInfo.coordList.map(item => {
        item.userName = item.name;
        item.employeeId = item.id;
        return item;
      });
      this.cooIdsInfo = coordList.length ? coordList : [];

      this.customermanagerInfo = this.corpBaseInfo.customerManagerId
        ? [
            {
              employeeId: this.corpBaseInfo.customerManagerId,
              employeeName: this.corpBaseInfo.customerManagerName,
              userName: this.corpBaseInfo.customerManagerName
            }
          ]
        : [];
      // 开票信息
      this.corpInvoiceInfo = JSON.parse(JSON.stringify(corpInvoiceInfo));
      this.billingCorpNameEnum = [
        {
          billingCorpName: corpInvoiceInfo.billingCorpName,
          billingCorpId: corpInvoiceInfo.billingCorpId
        }
      ];
      let credNeddToStringKey = [
        // 分行分行
        "bssType"
      ];
      credNeddToStringKey.map(item => {
        this.corpInvoiceInfo[item] += "";
      });
      // 联系人信息
      this.contactInfos = JSON.parse(JSON.stringify(contactInfos));
      this.contactInfos.map(item => {
        item.detailAddress = item.address;
        let contactTypeName = item.contactTypeName;
        if (contactTypeName && contactTypeName.length) {
          item.contactTypeName = contactTypeName.join(",");
        }
      });
      this.$nextTick(() => {
        this.$refs.invoiceDataForm.clearValidate();
        this.$refs.corpBaseFormCRM.clearValidate();
        this.$refs.corpBaseFormE3.clearValidate();
      });
    },
    // 选择开票
    billingCorpIdChange(val, node) {
      if (val) {
        this.corpInvoiceInfo.billingCorpName = node.billingCorpName;
        this.corpInvoiceInfo.bssType = node.bssType + "";
        this.corpInvoiceInfo.customerName = node.customerName;
      } else {
        this.corpInvoiceInfo.billingCorpName = "";
        this.corpInvoiceInfo.bssType = "";
      }
    },
    // 获取地址名称
    getDefualtCity(data) {
      console.log(data);
      let { provinceName, cityName, areaName, streetName } = data;
      let name = "";
      name += provinceName ? provinceName : "";
      name += cityName ? cityName : "";
      name += areaName ? areaName : "";
      name += streetName ? streetName : "";
      return name;
    },
    handleToggleSearchForm() {
      this.searchFormVisiable = !this.searchFormVisiable;
    },
    // 处理城市选择
    handleCitySelect(val) {
      let { address } = this.corpBaseInfo;
      address.provinceCode = val.province.code;
      address.provinceName = val.province.name;
      address.cityCode = val.city.code;
      address.cityName = val.city.name;
      address.countyCode = val.district.code;
      address.countyName = val.district.name;
      address.streetCode = val.street.code;
      address.streetName = val.street.name;
      this.corpBaseInfo.defualtCity = val.name;
    },
    //点击创建按钮
    handleAdd() {
      if (this.activeName == "one") {
        this.addContactVisible = true;
      }
    },
    //处理新增操作push进数组
    handlePush(params) {
      console.log(params);
      let { type, data } = params;
      if (type == 1) {
        // 这里字段不统一做一下兼容
        if (data.addressList?.length) {
          let addressDetail = data.address;
          data.address = data.addressList[0];
          let { address } = data;
          address.provinceCode = address.provinceId;
          address.cityCode = address.cityId;
          address.countyCode = address.areaId;
          address.countyName = address.areaName;
          address.streetCode = address.streetId;
          address.addressDetail = addressDetail;
        }
        data.peopleName = data.contactName;
        // data.phone = data.phone1;
        // data.phone1 = data.phone2;
        delete data.addressList;
        delete data.contactName;
        this.contactInfos.push(data);
      }
    },
    //处理删除操作
    handleDelete(params) {
      let { type, index } = params;
      if (type == 1) {
        let contactInfos = _.cloneDeep(this.contactInfos);
        contactInfos.splice(index, 1);
        this.contactInfos = contactInfos;
      }
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    },
    //点击确定新增客户
    handleSubmit() {
      if (this.corpId) {
        this.handleGoBack();
        return;
      }
      this.$refs.invoiceDataForm
        .validate()
        .then(() => {
          return this.$refs.corpBaseFormCRM.validate();
        })
        .then(() => {
          return this.$refs.corpBaseFormE3.validate();
        })
        .then(() => {
          if (!this.contactInfos.length) {
            this.$message.warning("请至少填写一个联系人");
            return;
          }
          console.log({
            corpBaseInfo: this.corpBaseInfo,
            corpInvoiceInfo: this.corpInvoiceInfo,
            contactInfos: this.contactInfos
          });
          this.addCustomer();
        });
    },
    //新增客户请求
    async addCustomer() {
      try {
        this.loading = true;
        let res = await createCorp({
          corpBaseInfo: this.corpBaseInfo,
          corpInvoiceInfo: this.corpInvoiceInfo,
          contactInfos: this.contactInfos
        });
        if (res.code === "OK") {
          this.$message.success(
            res.msg && res.msg.indexOf("审批中") !== -1
              ? "已进入飞书审批流程"
              : res.msg
          );
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    getPlaceholder(placeholder) {
      return this.corpId ? "" : placeholder;
    }
  }
};
</script>
<style lang="scss" scoped></style>
